import { Action } from 'redux';
import Staff from 'model/Staff';

//Action Types
export const STAFF_RESET_ACTION_TYPE = 'staff/reset_start';
export const STAFF_FETCH_START_ACTION_TYPE = 'staff/fetch_start';
export const STAFF_FETCH_SUCCESS_ACTION_TYPE = 'staff/fetch_success';
export const STAFF_GET_START_ACTION_TYPE = 'staff/get_start';
export const STAFF_GET_SUCCESS_ACTION_TYPE = 'staff/get_success';
export const STAFF_GET_BY_EMAIL_START_ACTION_TYPE = 'staff/get_by_email_start';
export const STAFF_GET_BY_EMAIL_SUCCESS_ACTION_TYPE =
    'staff/get_by_email_success';
export const STAFF_GET_BY_EMAIL_NOT_FOUND_ACTION_TYPE =
    'staff/get_by_email_not_found';
export const STAFF_SAVE_START_ACTION_TYPE = 'staff/save_start';
export const STAFF_SAVE_SUCCESS_ACTION_TYPE = 'staff/save_success';
export const STAFF_SAVE_COMMISSIONS_START_ACTION_TYPE =
    'staff/save_commissions_start';
export const STAFF_SAVE_COMMISSIONS_SUCCESS_ACTION_TYPE =
    'staff/save_commissions_success';
export const STAFF_SAVE_DEFAULT_SCHEDULE_START_ACTION_TYPE =
    'staff/save_default_schedule_start';
export const STAFF_SAVE_DEFAULT_SCHEDULE_SUCCESS_ACTION_TYPE =
    'staff/save_default_schedule_success';
export const STAFF_DELETE_START_ACTION_TYPE = 'staff/delete_start';
export const STAFF_DELETE_SUCCESS_ACTION_TYPE = 'staff/delete_success';
export const STAFF_ACTIVATE_DEACTIVATE_START_ACTION_TYPE =
    'staff/activate_deactivate_start';
export const STAFF_ACTIVATE_DEACTIVATE_SUCCESS_ACTION_TYPE =
    'staff/activate_deactivate_success';
export const STAFF_ERROR_ACTION_TYPE = 'staff/error';
export const STAFF_RESEND_INVITATION_START_ACTION_TYPE =
    'staff/resend_invitation_start';
export const STAFF_RESEND_INVITATION_SUCCESS_ACTION_TYPE =
    'staff/resend_invitation_success';
export const STAFF_RESEND_INVITATION_ERROR_ACTION_TYPE =
    'staff/resend_invitation_error';

//Actions
type StaffResetAction = Action<typeof STAFF_RESET_ACTION_TYPE>

type StaffFetchStartAction = Action<typeof STAFF_FETCH_START_ACTION_TYPE>

interface StaffFetchSuccessAction
    extends Action<typeof STAFF_FETCH_SUCCESS_ACTION_TYPE> {
    payload: {
        staff: Array<Staff>;
    };
}

type StaffGetStartAction = Action<typeof STAFF_GET_START_ACTION_TYPE>

interface StaffGetSuccessAction
    extends Action<typeof STAFF_GET_SUCCESS_ACTION_TYPE> {
    payload: {
        member: Staff;
    };
}

type StaffGetByEmailStartAction = Action<typeof STAFF_GET_BY_EMAIL_START_ACTION_TYPE>

interface StaffGetByEmailSuccessAction
    extends Action<typeof STAFF_GET_BY_EMAIL_SUCCESS_ACTION_TYPE> {
    payload: {
        member: Staff;
    };
}

type StaffGetByEmailNotFoundAction = Action<typeof STAFF_GET_BY_EMAIL_NOT_FOUND_ACTION_TYPE>

type StaffSaveStartAction = Action<typeof STAFF_SAVE_START_ACTION_TYPE>

interface StaffSaveSuccessAction
    extends Action<typeof STAFF_SAVE_SUCCESS_ACTION_TYPE> {
    payload: {
        savedStaff: Staff;
    };
}

type StaffSaveCommissionsStartAction = Action<typeof STAFF_SAVE_COMMISSIONS_START_ACTION_TYPE>
interface StaffSaveCommissionsSuccessAction
    extends Action<typeof STAFF_SAVE_COMMISSIONS_SUCCESS_ACTION_TYPE> {
    payload: {
        savedStaff: Staff;
    };
}

type StaffSaveDefaultScheduleStartAction = Action<typeof STAFF_SAVE_DEFAULT_SCHEDULE_START_ACTION_TYPE>

interface StaffSaveDefaultScheduleSuccessAction
    extends Action<typeof STAFF_SAVE_DEFAULT_SCHEDULE_SUCCESS_ACTION_TYPE> {
    payload: {
        savedStaff: Staff;
    };
}

interface StaffDeleteStartAction
    extends Action<typeof STAFF_DELETE_START_ACTION_TYPE> {
    payload: {
        deletedStaffId: number;
    };
}

interface StaffDeleteSuccessAction
    extends Action<typeof STAFF_DELETE_SUCCESS_ACTION_TYPE> {
    payload: {
        deletedStaffId: number;
    };
}

interface StaffActivateDeactivateStartAction
    extends Action<typeof STAFF_ACTIVATE_DEACTIVATE_START_ACTION_TYPE> {
    payload: {
        activate: boolean;
    };
}

interface StaffActivateDeactivateSuccessAction
    extends Action<typeof STAFF_ACTIVATE_DEACTIVATE_SUCCESS_ACTION_TYPE> {
    payload: {
        activate: boolean;
    };
}

type StaffErrorAction = Action<typeof STAFF_ERROR_ACTION_TYPE>

type StaffResendInvitationStartAction = Action<
    typeof STAFF_RESEND_INVITATION_START_ACTION_TYPE
>;

type StaffResendInvitationSuccessAction = Action<
    typeof STAFF_RESEND_INVITATION_SUCCESS_ACTION_TYPE
>;

type StaffResendInvitationErrorAction = Action<
    typeof STAFF_RESEND_INVITATION_ERROR_ACTION_TYPE
>;

export type StaffAction =
    | StaffResetAction
    | StaffFetchStartAction
    | StaffFetchSuccessAction
    | StaffGetStartAction
    | StaffGetSuccessAction
    | StaffGetByEmailStartAction
    | StaffGetByEmailSuccessAction
    | StaffGetByEmailNotFoundAction
    | StaffSaveStartAction
    | StaffSaveSuccessAction
    | StaffSaveCommissionsStartAction
    | StaffSaveCommissionsSuccessAction
    | StaffSaveDefaultScheduleStartAction
    | StaffSaveDefaultScheduleSuccessAction
    | StaffDeleteStartAction
    | StaffDeleteSuccessAction
    | StaffActivateDeactivateStartAction
    | StaffActivateDeactivateSuccessAction
    | StaffErrorAction
    | StaffResendInvitationStartAction
    | StaffResendInvitationSuccessAction
    | StaffResendInvitationErrorAction;
