import { faArrowLeft, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import Tabs, { blockedTimeTabId, bookingTabId } from './Tabs';

interface CreateBookingHeaderProps {
    title: string;
    depositCollect?: boolean;
    onClose?: () => void;
    onBack?: () => void;
    onShowBlockedTime?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        titleContainer: {
            display: 'flex',
            cursor: 'pointer'
        },
        title: {
            fontWeight: 600,
            fontSize: 20,
            lineHeight: '20px',
            [theme.breakpoints.up('md')]: {
                fontSize: 22,
                lineHeight: '22px'
            }
        },
        spacing: {
            paddingRight: 16,
            [theme.breakpoints.up('md')]: {
                paddingRight: 20
            }
        },
        icon: {
            cursor: 'pointer',
            fontSize: 22,
            [theme.breakpoints.up('md')]: {
                fontSize: 24
            }
        }
    })
);

export const CreateBookingHeader: FunctionComponent<CreateBookingHeaderProps> = props => {
    const classes = useStyles();

    const selectTabHandler = (tabId: string) => {
        tabId === blockedTimeTabId && props.onShowBlockedTime && props.onShowBlockedTime();
    };

    return props.onShowBlockedTime ? (
        <Tabs
            title={props.title}
            tabId={bookingTabId}
            depositCollect={props.depositCollect}
            onSelect={selectTabHandler}
            onClose={() => {
                props.depositCollect ? props.onBack && props.onBack() : props.onClose && props.onClose();
            }}
        />
    ) : (
        <Box className={classes.headerContainer}>
            <Box className={classes.titleContainer}>
                {props.onBack && (
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        className={clsx(classes.icon, classes.spacing)}
                        onClick={props.onBack}
                    />
                )}
                <Typography className={classes.title}>{props.title}</Typography>
            </Box>
            {props.onClose && <FontAwesomeIcon className={classes.icon} icon={faTimes} onClick={props.onClose} />}
        </Box>
    );
};

export default CreateBookingHeader;
