import {
    Box,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import {
    onboardingCloseModal,
    onboardingShowVideo
} from 'actions/onboardingSteps/OnboardingStepsActions';
import { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingStepsState } from 'reducers/onboardingSettings/onboardingStepsState';
import {
    BusinessSettingsPath,
    CalendarPath,
    ClientsPath,
    ServicesPath,
    StaffPath
} from 'routes/Paths';
import { RootState } from 'store';
import BusinessStep from './UI/BusinessStep';
import OnboardingDrawer from './UI/OnboardingDrawer';
import { OnboardingStepper, StepType } from './UI/OnboardingStepper';
import { OnboardingTitle } from './UI/OnboardingTitle';
import { PaymentStep } from './UI/PaymentStep';
import { PaymentStepSetUpMarketplace } from './UI/PaymentStepSetUpMarketplace';
import { ONBOARDING_VIDEOS } from './videos';

import { useAuth, useMarketplace } from 'hooks';
import { reduceResolution, wbp } from 'Theme';
import { OnBoardingPaymentsFlyer } from './UI/PaymentsFlyer';
import { useHubspotTrackingCode } from 'hooks';
import { isPaymentsEnabled } from 'utils/MarketingUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { ACCESS_LEVEL_ADMIN_ID } from '@spike/model';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints.down(wbp)]: {
                marginLeft: `${reduceResolution(26)}px`,
                marginRight: `${reduceResolution(46)}px`,
                marginTop: `${reduceResolution(23)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginLeft: '35px',
                marginRight: '35px',
                marginTop: '25px'
            },
            [theme.breakpoints.down('xs')]: {
                marginRight: 17
            }
        },

        stepperContainer: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(39)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '39px'
            }
        },
        recommendedNextStepsContainer: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(23)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '23px'
            }
        },
        learnTheSoftwareLinkStyle: {
            cursor: 'pointer',
            color: '#5E8677',
            display: 'flex',
            alignItems: 'center',
            marginTop: '7px',
            fontWeight: 500,
            fontSize: '16px'
        },
        videoIcon: { cursor: 'pointer', color: '#5E8677', marginRight: '7px' },
        caption: {
            color: '#7A7A7A',
            fontSize: '14px',
            lineHeight: '20px'
        },
        upgradeYourAccountContainer: {
            marginTop: '21px'
        },
        payStepsContainer: {
            marginTop: '34px'
        },
        closeIcon: {
            width: 20,
            height: 20
        }
    })
);
export const OnboardingSteps: FunctionComponent = () => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const marketplace = useMarketplace();
    const classes = useStyles();
    const dispatch = useDispatch();
    const auth = useAuth();

    //Get HubSpot hook
    const { setTrackEvent } = useHubspotTrackingCode();

    const { onboardingSteps, openModal } = useSelector<
        RootState,
        OnboardingStepsState
    >(state => state.onboardingSteps);

    const paymentsEnabled = isPaymentsEnabled(marketplace);

    const {
        businessHoursDone,
        teamDone,
        servicesDone,
        petTypesDone,
        clientsDone,
        bookingDone
    } = onboardingSteps;

    const handleBussinesHoursClick = () => {
        /*HubSpot hook trigger */
        setTrackEvent(
            `${process.env.REACT_APP_HUBSPOT_EVENT_ONBOARDING_STARTED_LEGACY}`
        );

        /*
    Manual trigger
    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(["trackEvent", {
      id: `${process.env.REACT_APP_HUBSPOT_EVENT_ONBOARDING_STARTED_LEGACY}`
    }]);
    */

        dispatch(onboardingCloseModal());
    };

    const steps: Array<StepType> = [
        {
            id: 1,
            stepContent: (
                <BusinessStep
                    stepNumber={1}
                    label="Add Business Hours"
                    firstLinkLabel={businessHoursDone ? 'Edit' : 'Add Now'}
                    firstURL={{
                        pathname: `${BusinessSettingsPath}/info/hours`
                    }}
                    onClickFirstLink={handleBussinesHoursClick}
                    secondLinkLabel="See How-To Video"
                    secondURL="#"
                    onClickSecondLink={() =>
                        dispatch(
                            onboardingShowVideo(
                                ONBOARDING_VIDEOS.BUSINESS_HOURS
                            )
                        )
                    }
                    checked={businessHoursDone}
                    buttonId={
                        businessHoursDone
                            ? 'onBoardingEditBusinessHours'
                            : 'onBoardingAddBusinessHours'
                    }
                />
            ),
            checked: businessHoursDone
        },
        {
            id: 2,
            stepContent: (
                <BusinessStep
                    stepNumber={2}
                    label="Add Team Schedule"
                    firstLinkLabel={teamDone ? 'Edit' : 'Add Now'}
                    firstURL={StaffPath}
                    onClickFirstLink={() => dispatch(onboardingCloseModal())}
                    secondLinkLabel="See How-To Video"
                    secondURL="#"
                    onClickSecondLink={() =>
                        dispatch(
                            onboardingShowVideo(ONBOARDING_VIDEOS.TEAM_SCHEDULE)
                        )
                    }
                    checked={teamDone}
                    buttonId={
                        teamDone ? 'onBoardingEditTeam' : 'onBoardingAddTeam'
                    }
                />
            ),
            checked: teamDone
        },
        {
            id: 3,
            stepContent: (
                <BusinessStep
                    stepNumber={3}
                    label="Add Pet Types"
                    firstLinkLabel={petTypesDone ? 'Edit' : 'Add Now'}
                    firstURL={{
                        pathname: `${BusinessSettingsPath}/pets/types`
                    }}
                    onClickFirstLink={() => dispatch(onboardingCloseModal())}
                    secondLinkLabel="See How-To Video"
                    secondURL="#"
                    onClickSecondLink={() =>
                        dispatch(
                            onboardingShowVideo(ONBOARDING_VIDEOS.ADD_SERVICE)
                        )
                    }
                    checked={petTypesDone}
                    buttonId={
                        petTypesDone
                            ? 'onBoardingEditPetTypes'
                            : 'onBoardingAddPetTypes'
                    }
                />
            ),
            checked: petTypesDone ?? false
        },
        {
            id: 4,
            stepContent: (
                <BusinessStep
                    stepNumber={4}
                    label="Add Services"
                    firstLinkLabel={servicesDone ? 'Edit' : 'Add Now'}
                    firstURL={ServicesPath}
                    onClickFirstLink={() => dispatch(onboardingCloseModal())}
                    secondLinkLabel="See How-To Video"
                    secondURL="#"
                    onClickSecondLink={() =>
                        dispatch(
                            onboardingShowVideo(ONBOARDING_VIDEOS.ADD_SERVICE)
                        )
                    }
                    checked={servicesDone}
                    buttonId={
                        servicesDone
                            ? 'onBoardingEditServices'
                            : 'onBoardingAddServices'
                    }
                />
            ),
            checked: servicesDone
        },
        {
            id: 5,
            stepContent: (
                <BusinessStep
                    stepNumber={5}
                    label="Add your First Client"
                    firstLinkLabel={clientsDone ? 'Edit' : 'Add Now'}
                    firstURL={ClientsPath}
                    onClickFirstLink={() => dispatch(onboardingCloseModal())}
                    secondLinkLabel="See How-To Video"
                    secondURL="#"
                    onClickSecondLink={() =>
                        dispatch(
                            onboardingShowVideo(ONBOARDING_VIDEOS.ADD_SERVICE)
                        )
                    }
                    checked={clientsDone}
                    buttonId={
                        clientsDone
                            ? 'onBoardingEditClient'
                            : 'onBoardingAddClient'
                    }
                />
            ),
            checked: clientsDone
        },
        {
            id: 6,
            stepContent: (
                <BusinessStep
                    stepNumber={6}
                    label="Book an Appointment"
                    firstLinkLabel={bookingDone ? 'Edit' : 'Book Now'}
                    firstURL={CalendarPath}
                    onClickFirstLink={() => dispatch(onboardingCloseModal())}
                    secondLinkLabel="See How-To Video"
                    secondURL="#"
                    onClickSecondLink={() =>
                        dispatch(
                            onboardingShowVideo(ONBOARDING_VIDEOS.ADD_SERVICE)
                        )
                    }
                    checked={bookingDone}
                    buttonId={
                        clientsDone
                            ? 'onBoardingEditBooking'
                            : 'onBoardingAddBooking'
                    }
                />
            ),
            checked: bookingDone ?? false
        }
    ];

    const paySteps: Array<StepType> = [
        {
            id: 1,
            checked: false,
            stepContent: <PaymentStep isNew={false} onClickPay={() => {}} />
        },
        {
            id: 2,
            checked: false,
            stepContent: (
                <PaymentStepSetUpMarketplace
                    isNew={false}
                    onClickFindOutMore={() => {}}
                />
            )
        }
    ];

    return (
        <>
            {(!businessHoursDone ||
                !teamDone ||
                !petTypesDone ||
                !servicesDone ||
                !clientsDone ||
                !bookingDone) &&
                auth.user?.accessLevel.id === ACCESS_LEVEL_ADMIN_ID && (
                    <OnboardingDrawer
                        open={openModal}
                        close={() => dispatch(onboardingCloseModal())}
                    >
                        <Box className={classes.container}>
                            <Grid container>
                                <Grid
                                    container
                                    sm={12}
                                    justifyContent="flex-end"
                                    alignItems="flex-end"
                                    direction="row"
                                >
                                    {isMobile && (
                                        <FontAwesomeIcon
                                            icon={faXmark}
                                            onClick={() =>
                                                dispatch(onboardingCloseModal())
                                            }
                                            className={classes.closeIcon}
                                        />
                                    )}
                                </Grid>
                                <Grid container>
                                    <OnboardingTitle
                                        title="Set Up Your Account"
                                        subtitle="Follow these steps to start booking clients!"
                                        alertIncon
                                    />
                                </Grid>
                                <Grid
                                    container
                                    className={classes.stepperContainer}
                                >
                                    <OnboardingStepper steps={steps} />
                                </Grid>
                                {paymentsEnabled && (
                                    <Grid container>
                                        <OnBoardingPaymentsFlyer
                                            isNew={false}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </OnboardingDrawer>
                )}
        </>
    );
};

export default OnboardingSteps;
