import { StaffState, StaffStatus } from "./StaffState";
import {
  STAFF_RESET_ACTION_TYPE,
  STAFF_FETCH_START_ACTION_TYPE,
  STAFF_FETCH_SUCCESS_ACTION_TYPE,
  STAFF_GET_START_ACTION_TYPE,
  STAFF_GET_SUCCESS_ACTION_TYPE,
  STAFF_GET_BY_EMAIL_START_ACTION_TYPE,
  STAFF_GET_BY_EMAIL_SUCCESS_ACTION_TYPE,
  STAFF_GET_BY_EMAIL_NOT_FOUND_ACTION_TYPE,
  STAFF_SAVE_START_ACTION_TYPE,
  STAFF_SAVE_SUCCESS_ACTION_TYPE,
  STAFF_SAVE_COMMISSIONS_START_ACTION_TYPE,
  STAFF_SAVE_COMMISSIONS_SUCCESS_ACTION_TYPE,
  STAFF_SAVE_DEFAULT_SCHEDULE_START_ACTION_TYPE,
  STAFF_SAVE_DEFAULT_SCHEDULE_SUCCESS_ACTION_TYPE,
  STAFF_DELETE_START_ACTION_TYPE,
  STAFF_DELETE_SUCCESS_ACTION_TYPE,
  STAFF_ACTIVATE_DEACTIVATE_START_ACTION_TYPE,
  STAFF_ACTIVATE_DEACTIVATE_SUCCESS_ACTION_TYPE,
  STAFF_ERROR_ACTION_TYPE,
  StaffAction,
} from "actions/staff/StaffActionsTypes";
import { Reducer } from "redux";

const initialState: StaffState = {
  staff: [],
  status: StaffStatus.Initial,
  loading: false,
};

export const staffReducer: Reducer<StaffState, StaffAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case STAFF_RESET_ACTION_TYPE:
      return {
        ...initialState
      };
    case STAFF_FETCH_START_ACTION_TYPE:
      return {
        ...state,
        status: StaffStatus.Fetching,
        loading: true,
      };
    case STAFF_FETCH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: StaffStatus.FetchSuccess,
        staff: action.payload.staff,
        loading: false,
      };
    case STAFF_GET_START_ACTION_TYPE:
      return {
        ...state,
        member: undefined,
        status: StaffStatus.Getting,
        loading: true,
      };
    case STAFF_GET_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: StaffStatus.GetSuccess,
        member: action.payload.member,
        loading: false,
      };
    case STAFF_GET_BY_EMAIL_START_ACTION_TYPE:
      return {
        ...state,
        member: undefined,
        status: StaffStatus.GettingByEmail,
        loading: true,
      };
    case STAFF_GET_BY_EMAIL_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: StaffStatus.GetByEmailSuccess,
        member: action.payload.member,
        loading: false,
      };
    case STAFF_GET_BY_EMAIL_NOT_FOUND_ACTION_TYPE:
      return {
        ...state,
        member: undefined,
        status: StaffStatus.GetByEmailNotFound,
        loading: false,
      };
    case STAFF_SAVE_START_ACTION_TYPE:
      return {
        ...state,
        status: StaffStatus.Saving,
        loading: true,
      };
    case STAFF_SAVE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: StaffStatus.SaveSuccess,
        loading: false,
        savedStaff: action.payload.savedStaff,
        member: state.member?.id === action.payload.savedStaff.id ? action.payload.savedStaff : state.member,
        staff: state.staff.filter(s => s.id === action.payload.savedStaff.id ? {...action.payload.savedStaff} : s),
      };
    case STAFF_SAVE_COMMISSIONS_START_ACTION_TYPE:
      return {
        ...state,
        status: StaffStatus.SavingCommissions,
        loading: true,
      };
    case STAFF_SAVE_COMMISSIONS_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: StaffStatus.SaveCommissionsSuccess,
        loading: false,
        savedStaff: action.payload.savedStaff,
        member: state.member?.id === action.payload.savedStaff.id ? action.payload.savedStaff : state.member,
        staff: state.staff.filter(s => s.id === action.payload.savedStaff.id ? {...action.payload.savedStaff} : s),
      };
    case STAFF_SAVE_DEFAULT_SCHEDULE_START_ACTION_TYPE:
      return {
        ...state,
        status: StaffStatus.SavingDefaultSchedule,
        loading: true,
      };
    case STAFF_SAVE_DEFAULT_SCHEDULE_SUCCESS_ACTION_TYPE:
        return {
          ...state,
          status: StaffStatus.SaveDefaultScheduleSuccess,
          loading: false,
          savedStaff: {...action.payload.savedStaff},
          member: state.member?.id === action.payload.savedStaff.id ? {...action.payload.savedStaff} : state.member,
          staff: state.staff.filter(s => s.id === action.payload.savedStaff.id ? {...action.payload.savedStaff} : s),
        };
    case STAFF_DELETE_START_ACTION_TYPE:
      return {
        ...state,
        status: StaffStatus.Deleting,
        loading: true,
        staff: state.staff.filter(staff => staff.id !== action.payload.deletedStaffId),
        savedStaff: state.savedStaff?.id === action.payload.deletedStaffId ? undefined : state.savedStaff,
        member: state.member?.id === action.payload.deletedStaffId ? undefined : state.member,
      };
    case STAFF_DELETE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: StaffStatus.DeleteSuccess,
        loading: false,
        staff: state.staff.filter(staff => staff.id !== action.payload.deletedStaffId),
        savedStaff: state.savedStaff?.id === action.payload.deletedStaffId ? undefined : state.savedStaff,
        member: state.member?.id === action.payload.deletedStaffId ? undefined : state.member,
      };
    case STAFF_ACTIVATE_DEACTIVATE_START_ACTION_TYPE:
      return {
        ...state,
        status: action.payload.activate ? StaffStatus.Activating : StaffStatus.Deactivating,
        loading: true,
      };
    case STAFF_ACTIVATE_DEACTIVATE_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        status: action.payload.activate ? StaffStatus.ActivateSuccess : StaffStatus.DeactivateSuccess,
        loading: false,
      };
    case STAFF_ERROR_ACTION_TYPE:
      return {
        ...state,
        status: StaffStatus.Error,
        loading: false,
      };
    default:
      return state;
  }
};

export default staffReducer;
