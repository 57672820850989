import { MessageDialog2 } from 'components/UI';
import { FunctionComponent } from 'react';

interface ActionNotSupportedDialogProps {
    onClose: () => void;
}

export const ActionNotSupportedDialog: FunctionComponent<
    ActionNotSupportedDialogProps
> = props => {
    return (
        <MessageDialog2
            title="Action Not Supported"
            message={[
                'To adjust a multi-service booking,',
                'please edit the booking details.'
            ]}
            onClose={props.onClose}
        />
    );
};

export default ActionNotSupportedDialog;
