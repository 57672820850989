import {ThunkAction} from "redux-thunk";
import {BulkUploadState} from "../../reducers/bulkUpload/BulkUploadState";
import {
    BULKUPLOAD_ERROR_UPLOADING_FILE_TYPE,
    BULKUPLOAD_PROGRESS_TYPE,
    BULKUPLOAD_START_UPLOAD_FILE_TYPE,
    BulkUploadAction
} from "./bulkUploadActionType";
import {BulkUploadDto} from "./bulkUploadDto";
import {serialize} from "object-to-formdata";
import {AxiosResponse} from "axios";
import {FileUploadDto} from "./fileUploadDto";
import ApiClient, {createMultipartTokenConfig, isCancelled} from "../../api/ApiClient";
import store from "../../store";
import {alertErrorHandler} from "@spike/notifications-action";

export const setProgress = (bulkUploadProgress: BulkUploadDto): BulkUploadAction => {
    return {
        type: BULKUPLOAD_PROGRESS_TYPE,
        payload: {
            event: bulkUploadProgress.event,
            errors: bulkUploadProgress.errors,
            percentage: bulkUploadProgress.percentage,
            record_number: bulkUploadProgress.record_number,
            successfully_imported: bulkUploadProgress.successfully_imported,
            total_records: bulkUploadProgress.total_records
        }
    }
}

export const setBulkUploadThunk = (bulkUpload: BulkUploadDto): ThunkAction<void, BulkUploadState, null, BulkUploadAction> => {
    return async (dispatch)=> {
        try{
            dispatch(setProgress(bulkUpload))
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                console.info(apiError);
                alertErrorHandler(apiError, dispatch, "Error processing csv.");            }
        }
    }
}

export const uploadFileThunk = async (data: any) => {
    let response = null;
        try{
            let formData = new FormData();
            const marketplaceId = store.getState().login.auth.marketplaceId;
            const json = JSON.stringify(data);
            const blob = new Blob([json], {
                type: 'application/json'
            });
            const options = {
                indices: false,
                nullsAsUndefineds: false,
                booleansAsIntegers: false,
                allowEmptyArrays: true,
            };
            formData = serialize({marketplace_id: marketplaceId, file: blob}, options, formData);
            const url = `customers_import`
            const response: AxiosResponse<FileUploadDto> = await ApiClient.post(url, formData, createMultipartTokenConfig(store.getState().login.auth.token!));
            return response;
        }catch (apiError) {
            console.error(apiError)
        }
    return response;
}
