import store from 'store';
import MasterData from '@spike/masterdata-model';
import Marketplace from '@spike/marketplace-model';
import {
    ServiceDto,
    FixedPriceOptionDto,
    VariablePriceDto,
    VariableDurationDto
} from './ServicesDtos';
import {
    Service,
    FixedPriceOption,
    VariablePrice,
    ServiceDuration,
    Duration,
    VariableDuration
} from 'model/Service';
import { Option, PetTypeIds, OrderedOption } from '@spike/model';
import moment from 'moment';

export const convertToService = (serviceDto: ServiceDto): Service => {
    const masterData: MasterData = store.getState().masterData.data;
    return {
        id: serviceDto.id,
        uuid: serviceDto.uuid,
        name: serviceDto.name,
        description: serviceDto.description || '',
        duration: convertDuration(serviceDto),
        pricing: {
            taxable: serviceDto.includes_tax,
            taxes: serviceDto.taxes
                .sort(function (a, b) {
                    return moment(a.created_at) > moment(b.created_at)
                        ? 1
                        : moment(b.created_at) > moment(a.created_at)
                        ? -1
                        : 0;
                })
                .map(tax => ({
                    id: tax.id,
                    uuid: tax.uuid,
                    name: tax.name,
                    percentage: tax.value
                })),
            fixedPrice: convertFixedPrice(serviceDto),
            variablePrice: convertVariablePrice(serviceDto)
        },
        businessArea: masterData.businessAreas.find(
            ba => ba.id === serviceDto.business_area
        )!,
        petTypes: masterData.petTypes.filter(pt =>
            serviceDto.pet_list.includes(pt.id)
        )!,
        staff: serviceDto.staff.map(s => ({
            id: s.id,
            firstName: s.first_name,
            lastName: s.last_name,
            avatar: s.image
        })),
        status: masterData.status.find(
            status => status.id === serviceDto.status_id
        )!,
        tags: serviceDto.tag_list,
        destroyable: serviceDto.destroyable,
        editable: serviceDto.editable,
        hasReport: serviceDto.has_report,
        houseCall: serviceDto.house_call,
        olbEnabled:
            serviceDto.olb_enabled === undefined ||
            serviceDto.olb_enabled === null
                ? true
                : serviceDto.olb_enabled
    };
};

const convertDuration = (serviceDto: ServiceDto): ServiceDuration => {
    return {
        simple: convertSimpleDuration(serviceDto),
        variable: convertVariableDuration(serviceDto)
    };
};

const convertSimpleDuration = (
    serviceDto: ServiceDto
): Duration | undefined => {
    return serviceDto.duration_hours !== null &&
        serviceDto.duration_minutes !== null
        ? {
              hours: serviceDto.duration_hours,
              minutes: serviceDto.duration_minutes
          }
        : undefined;
};

const convertVariableDuration = (
    serviceDto: ServiceDto
): Array<VariableDuration> => {
    const marketplace = store.getState().marketplace.marketplace;
    const petSizes = marketplace.petTypes.dogs?.sizes || [];

    return serviceDto.variable_duration
        ? JSON.parse(serviceDto.variable_duration).map(
              (vd: VariableDurationDto) => {
                  const petSize = petSizes.find(
                      ps => ps.element.id === vd.size_meta_variable
                  );
                  return {
                      petSize: {
                          id: petSize?.element.id,
                          name: petSize?.element.name
                      },
                      duration: { hours: vd.hours, minutes: vd.minutes }
                  };
              }
          )
        : [];
};

const convertFixedPrice = (serviceDto: ServiceDto): Array<FixedPriceOption> => {
    const masterData: MasterData = store.getState().masterData.data;
    if (serviceDto.fixed_price === null) {
        return [];
    } else {
        const responseFixedPrice: Array<FixedPriceOptionDto> = JSON.parse(
            serviceDto.fixed_price
        );

        return responseFixedPrice.map(fp => ({
            petType: masterData.petTypes.find(pt => pt.id === fp.pet_type_id)!,
            price: fp.price
        }));
    }
};

const convertVariablePrice = (
    serviceDto: ServiceDto
): VariablePrice | undefined => {
    const masterData: MasterData = store.getState().masterData.data;
    const marketplace: Marketplace = store.getState().marketplace.marketplace;

    if (serviceDto.variable_price === null) {
        return undefined;
    } else {
        const responseVariablePrice: VariablePriceDto = JSON.parse(
            serviceDto.variable_price
        );
        return {
            variables: {
                petTypeVariable:
                    responseVariablePrice.variables.pet_type_variable,
                petSizeVariable:
                    responseVariablePrice.variables.pet_size_variable,
                hairTypeVariable:
                    responseVariablePrice.variables.hair_type_variable,
                hairLengthVariable:
                    responseVariablePrice.variables.hair_length_variable,
                exoticTypeVariable:
                    responseVariablePrice.variables.exotic_type_variable
            },
            prices: responseVariablePrice.prices.map(vp => {
                const petType = masterData.petTypes.find(
                    pt => pt.id === vp.pet_type_id
                );
                let petSize = marketplace.petTypes.dogs?.sizes
                    .map(s => s.element)
                    .find(s => s.id === vp.size_meta_variable);
                let hairType =
                    petType?.id === 'dogs'
                        ? marketplace.petTypes.dogs?.hairTypes
                              .map(ht => ht.element)
                              .find(ht => ht.id === vp.hair_type_meta_variable)
                        : marketplace.petTypes.cats?.hairTypes
                              .map(ht => ht.element)
                              .find(ht => ht.id === vp.hair_type_meta_variable);
                let hairLength =
                    petType?.id === 'dogs'
                        ? marketplace.petTypes.dogs?.hairLengths
                              .map(ht => ht.element)
                              .find(ht => ht.id === vp.hair_type_meta_variable)
                        : marketplace.petTypes.cats?.hairLengths
                              .map(ht => ht.element)
                              .find(ht => ht.id === vp.hair_type_meta_variable);
                let exoticType = marketplace.petTypes.exotics?.types.find(
                    e => e.id === vp.exotic_type_meta_variable
                );

                if (petSize === undefined) {
                    const masterDataPetSizeOption = masterData.petSizes
                        .map(s => s.element)
                        .find(s => s.id === vp.size_meta_variable);
                    if (masterDataPetSizeOption !== undefined) {
                        petSize = {
                            ...masterDataPetSizeOption
                        };
                    }
                }

                if (hairType === undefined) {
                    let masterDataHaitTypes: Array<OrderedOption<string>> = [];

                    if (petType?.id === PetTypeIds.DOGS) {
                        masterDataHaitTypes = masterData.dogHairTypes;
                    } else if (petType?.id === PetTypeIds.CATS) {
                        masterDataHaitTypes = masterData.catHairTypes;
                    }
                    hairType = masterDataHaitTypes
                        .map(ht => ht.element)
                        .find(ht => ht.id === vp.hair_type_meta_variable);
                }

                if (hairLength === undefined) {
                    let masterDataHairLengths: Array<OrderedOption<string>> =
                        [];

                    if (petType?.id === PetTypeIds.DOGS) {
                        masterDataHairLengths = masterData.dogHairLengths;
                    } else if (petType?.id === PetTypeIds.CATS) {
                        masterDataHairLengths = masterData.catHairLengths;
                    }
                    hairLength = masterDataHairLengths
                        .map(hl => hl.element)
                        .find(hl => hl.id === vp.hair_length_meta_variable);
                }

                if (exoticType === undefined) {
                    exoticType = marketplace.petTypes.exotics?.others
                        .map(other => ({ id: other, name: other }))
                        .find(
                            other => other.id === vp.exotic_type_meta_variable
                        );
                }

                if (exoticType === undefined) {
                    exoticType = masterData.petExoticTypes.find(
                        et => et.id === vp.exotic_type_meta_variable
                    );
                }

                return {
                    id: vp.id,
                    uuid: vp.uuid,
                    petType,
                    petSize,
                    hairType,
                    hairLength,
                    exoticType,
                    price: vp.price
                };
            })
        };
    }
};
