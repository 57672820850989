import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheckCircle,
	faExclamationCircle
} from '@fortawesome/pro-solid-svg-icons';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import Service from 'model/Service';
import { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
	title: string;
	onEdit: () => void;
	withoutStaff?: boolean;
	pending?: boolean;
	service?: Service;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			background: '#FFFFFF',
			[theme.breakpoints.down('lg')]: {
				borderRadius: '18px'
			},
			[theme.breakpoints.up('xl')]: {
				borderRadius: '24px'
			}
		},
		headerContainer: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			paddingTop: 27,
			paddingBottom: 12
		},
		leftHeaderContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			[theme.breakpoints.down('lg')]: {
				width: '75px',
				paddingRight: '9px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '101px',
				paddingRight: '12px'
			},
			[theme.breakpoints.down('md')]: {
				width: 'auto',
				paddingRight: '16px'
			}
		},
		centerHeaderContainer: {
			display: 'flex',
			flex: '1 1 auto',
			alignItems: 'center'
		},
		rightHeaderContainer: {
			display: 'flex',
			justifyContent: 'flex-star',
			alignItems: 'center',
			[theme.breakpoints.down('lg')]: {
				width: '80px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '107px'
			},
			[theme.breakpoints.down('md')]: {
				justifyContent: 'flex-end'
			}
		},
		check: {
			color: '#5E8677'
		},
		exclamation: {
			color: 'black'
		},
		pencil: {
			marginRight: '5px',
			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		},
		exclamationStaff: {
			color: '#EAB464'
		},
		title: {
			fontWeight: 600,
			[theme.breakpoints.down('lg')]: {
				fontSize: '15px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '20px'
			},
			[theme.breakpoints.down('md')]: {
				fontSize: '16px'
			}
		},
		editLink: {
			cursor: 'pointer',
			textDecoration: 'underline',
			fontWeight: 500,
			[theme.breakpoints.down('lg')]: {
				fontSize: '14px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '18px'
			},
			[theme.breakpoints.down('md')]: {
				color: '#92B4A7',
				textDecoration: 'underline',
				textUnderlineOffset: '4px',
				fontWeight: '500'
			}
		},
		bodyContainer: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			[theme.breakpoints.down('md')]: {
				paddingLeft: '34px',
				width: 'calc(103%)'
			}
		}
	})
);

export const Section: FunctionComponent<Props> = props => {
	const classes = useStyles();

	const check = (
		<FontAwesomeIcon
			size="lg"
			icon={faCheckCircle}
			className={classes.check}
		/>
	);

	const exclamation = (
		<FontAwesomeIcon
			size="lg"
			icon={faExclamationCircle}
			className={
				props.withoutStaff
					? classes.exclamationStaff
					: classes.exclamation
			}
		/>
	);

	return (
		<Box className={classes.container}>
			<Box className={classes.headerContainer}>
				<Box className={classes.leftHeaderContainer}>
					{props.pending
						? exclamation
						: props.withoutStaff
						? exclamation
						: check}
				</Box>
				<Box className={classes.centerHeaderContainer}>
					<Typography className={classes.title}>
						{props.title}
					</Typography>
				</Box>
				<Box className={classes.rightHeaderContainer}>
					<Box onClick={props.onEdit}>
						<Typography className={classes.editLink}>
							<FontAwesomeIcon
								size="sm"
								icon={faPencil}
								className={classes.pencil}
							/>
							Edit
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box className={classes.bodyContainer}>{props.children}</Box>
		</Box>
	);
};

export default Section;
