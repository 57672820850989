import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { MarketplaceTimeLimit } from '@spike/marketplace-model';
import { FieldError, Option } from '@spike/model';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';
import { SelectField } from 'components/UI';
import { FunctionComponent, useState } from 'react';

export interface LimitProps {
    label: string;
    name: string;
    errors: Array<FieldError>;
    limits: Array<MarketplaceTimeLimit>;
    selected: MarketplaceTimeLimit | null;
    disabled?: boolean;
    className?: string;
    isMobile?: boolean;
    onSelect?: (limit: MarketplaceTimeLimit | null) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: (props: LimitProps) => ({
            display: 'flex',
            flexDirection: props.isMobile ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        }),
        left: (props: LimitProps) => ({
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: props.isMobile ? '100%' : undefined
        }),
        right: (props: LimitProps) => ({
            display: 'flex',
            alignItems: 'center',
            width: props.isMobile ? '100%' : undefined
        }),
        label: (props: LimitProps) => ({
            color: '#222222',
            marginBottom: props.isMobile ? '16px' : undefined,
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '25px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '140%'
            }
        }),
        error: {
            color: '#EA6464',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(14)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '14px'
            }
        },
        select: (props: LimitProps) => ({
            [theme.breakpoints.down(wbp)]: {
                minWidth: props.isMobile ? '100%' : `${reduceResolution(250)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                minWidth: props.isMobile ? '100%' : '250px'
            }
        })
    })
);

export const Limit: FunctionComponent<LimitProps> = props => {
    const classes = useStyles(props);

    const [selected, setSelected] = useState(props.selected);

    const error = props.errors.find(error => error.fieldName === props.name);

    const selectHandler = (option: Option<string | number>) => {
        const selectedTimeLimit = props.limits.find(limit => limit.id === option.id) ?? null;
        setSelected(selectedTimeLimit);
        props.onSelect && props.onSelect(selectedTimeLimit);
    };
    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.left}>
                <Typography className={classes.label}>{props.label}</Typography>
                {error && <Typography className={classes.error}>{error.errorMessage}</Typography>}
            </Box>
            <Box className={classes.right}>
                <SelectField
                    options={props.limits.map(limit => ({
                        id: limit.id,
                        name: limit.name
                    }))}
                    selectedOption={
                        selected === null
                            ? undefined
                            : {
                                  id: selected.id,
                                  name: selected.name
                              }
                    }
                    onSelect={selectHandler}
                    name={props.name}
                    errors={props.errors}
                    className={classes.select}
                    disabled={props.disabled}
                />
            </Box>
        </Box>
    );
};

export default Limit;
