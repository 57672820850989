import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';

interface WindowSize {
	height: number;
	width: number;
}

const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState<WindowSize>({
		height: window.innerHeight,
		width: window.innerWidth
	});

	const [isPortrait, setIsPortrait] = useState<boolean>(
		window.innerHeight > window.innerWidth
	);
	const [isAdressbarActive, setIsAdressbarActive] = useState<boolean>(true);
	const [currentAddressbarHeight, setCurrentAdressbarHeight] = useState<
		number | null
	>();

	const handleResize = () => {
		const newViewportHeight = Math.max(
			document.documentElement.clientHeight,
			window.innerHeight
		);
		const newViewportWidth = Math.max(
			document.documentElement.clientWidth,
			window.innerWidth
		);
		const hasOrientationChanged =
			newViewportHeight > newViewportWidth !== isPortrait;

		if (!hasOrientationChanged && newViewportHeight !== windowSize.height) {
			const addressbarHeight = Math.abs(
				newViewportHeight - windowSize.height
			);
			if (newViewportHeight < windowSize.height) {
				setIsAdressbarActive(true);
				setCurrentAdressbarHeight(addressbarHeight);
			} else {
				setIsAdressbarActive(false);
				setCurrentAdressbarHeight(addressbarHeight);
			}
		} else {
			setIsPortrait(true);
		}

		setWindowSize({
			height: newViewportHeight,
			width: newViewportWidth
		});
		setIsPortrait(newViewportHeight > newViewportWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		window.addEventListener('load', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('load', handleResize);
		};
	}, [windowSize.height, isPortrait]);

	return {
		currentAddressbarHeight,
		isAdressbarActive,
		windowSize,
		isPortrait,
		testValues:
			process.env.NODE_ENV === 'development' ? (
				<Typography style={{}}>
					{JSON.stringify({ windowSize, isAdressbarActive })}
				</Typography>
			) : undefined
	};
};

export default useWindowSize;
