import AppointmentsWebSockets from "WebSockets";
import CalendarComponent from "components/Calendar3/Calendar";
import { FunctionComponent } from "react";
import PrivatePage from "./PrivatePage";

export const Calendar: FunctionComponent = () => {
  return (<>
    <AppointmentsWebSockets />
    <PrivatePage title="Calendar - Cuddles" pageName="/calendar">
      <CalendarComponent />
    </PrivatePage>
  </>);
};

export default Calendar;
