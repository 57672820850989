import {ThunkAction} from "redux-thunk";
import store from "../../store";
import {AxiosResponse} from "axios";
import ApiClient from "../../api";
import {createTokenConfig, isCancelled} from "../../api/ApiClient";
import { PetTypeStatusState } from "reducers/businessSettings/PetTypeStatusState";
import {
    BusinessSettingsAction,
    BUSINESS_SETTINGS_ERROR_ACTION_TYPE,
    BUSINESS_SETTINGS_FETCH_START_ACTION_TYPE,
    BUSINESS_SETTINGS_FETCH_SUCCESS_ACTION_TYPE,
    OLB_ACTIVATE_DEACTIVATE_SUCCESS_ON_TYPE,
    OLB_ACTIVATE_DEACTIVATE_SUCCESS_OFF_TYPE
} from "./BusinessSettingsActionsTypes";
import {OLBStatusState} from "../../reducers/businessSettings/OLBStatusState";

const marketplaceUrl = "/marketplace/"

export const fetchPetTypeStatusThunk = (): ThunkAction<void, PetTypeStatusState, null, BusinessSettingsAction> => {
    return async (dispatch) => {
        dispatch(fetchStart())
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${marketplaceUrl}pet_type_status?marketplace_id=${marketplaceId}`;

        try {
            const response: AxiosResponse<PetTypeStatusState> = await ApiClient.get(url, createTokenConfig(store.getState().login.auth.token!));
            dispatch(fetchSuccess(response.data));
        } catch (apiError) {
            if(!isCancelled(apiError)) {
                dispatch(error());
            }
        }
    }
}

export const activateDeactivateOLBThunk = (activate: boolean): ThunkAction<void, OLBStatusState, null, BusinessSettingsAction> => {
    return async (dispatch) => {
        dispatch(fetchStart())
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${marketplaceUrl}${marketplaceId}/toggle_url_slug?set_url_slug=${ activate ? 'on' : 'off' }`;

        try {
            const response: AxiosResponse<OLBStatusState> = await ApiClient.get(url, createTokenConfig(store.getState().login.auth.token!));
            console.log(response.data)
            if(response.data.status == 'OK'){
                dispatch(updateStatusOLB(activate));
            }

        } catch (apiError) {
            if(!isCancelled(apiError)) {
                dispatch(error());
            }
        }
    }
}

const updateStatusOLB = (active: boolean): BusinessSettingsAction => {
    if(active) {
        return {
            type: OLB_ACTIVATE_DEACTIVATE_SUCCESS_ON_TYPE,
            payload: {
                status: 'active'
            }
        }
    } else {
        return {
            type: OLB_ACTIVATE_DEACTIVATE_SUCCESS_OFF_TYPE,
            payload: {
                status: 'inactive'
            }
        }
    }
}

const fetchStart = (): BusinessSettingsAction => {
    return {
        type: BUSINESS_SETTINGS_FETCH_START_ACTION_TYPE
    }
}

export const fetchSuccess = (petTypeStatusState: PetTypeStatusState): BusinessSettingsAction => {
    return {
        type: BUSINESS_SETTINGS_FETCH_SUCCESS_ACTION_TYPE,
        payload: {
            pet_size: petTypeStatusState.pet_size,
            pet_age: petTypeStatusState.pet_age,
            pet_hair_type: petTypeStatusState.pet_hair_type,
            pet_hair_length: petTypeStatusState.pet_hair_length,
            exotic_types: petTypeStatusState.exotic_types
        }
    }
}

const error = (): BusinessSettingsAction => {
    return {
        type: BUSINESS_SETTINGS_ERROR_ACTION_TYPE
    }
}
