import { MarketplaceOnlineBooking } from '@spike/marketplace-model';
import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import useNonInitialEffect from '@versiondos/hooks';
import { TextFieldWithButton } from 'components/UI';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import clsx from 'clsx';
import { FunctionComponent, useState } from 'react';

export interface Props {
    onlineBooking: MarketplaceOnlineBooking;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingTop: '36px'
        },
        row: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.only('md')]: {
                marginTop: '4px',
                marginBottom: '4px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '10px',
                marginBottom: '10px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '13px',
                marginBottom: '13px'
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '10px',
                marginBottom: '10px'
            }
        },
        rowInput: {
            [theme.breakpoints.down('sm')]: {
                '& input': {
                    height: '52px !important',
                    fontSize: '16px',
                    fontWeight: '400',
                    textOverflow: 'ellipsis !important',
                    paddingRight: '5px !important'
                },
                '& button': {
                    'height': '37px',
                    'width': '100px',
                    'paddingLeft': '16px',
                    'paddingRight': '15px',
                    '& .MuiButton-label': {
                        'fontSize': '14px',
                        'fontWeight': '600',
                        '& .MuiButton-startIcon': {
                            'marginRight': '7px',
                            '& svg': {
                                width: '14px',
                                height: '14px'
                            }
                        }
                    }
                },
                '& fieldset': {
                    borderColor: '#f00 !important'
                }
            }
        },
        subtitle: {
            width: '100%',
            fontSize: '16px',
            lineHeight: '25px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '140%',
                marginBottom: '0px'
            }
        }
    })
);

export const Profile: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const onlineBookingPath = `${process.env.REACT_APP_ONLINE_BOOKING_HOST_URL}${props.onlineBooking.slug}`;

    const [buttonMessage, setButtonMessage] = useState('Copy');
    const [buttonIcon, setButtonIcon] = useState(faCopy);
    const [urlCopied, setUrlCopied] = useState(false);

    useNonInitialEffect(() => {
        setButtonMessage('Copied');
        setButtonIcon(faCheck);
    }, [urlCopied]);

    const copyToClipboard = () => {
        setUrlCopied(true);
        if ('clipboard' in navigator) {
            navigator.clipboard.writeText(onlineBookingPath);
        } else {
            document.execCommand('copy', true, onlineBookingPath);
        }
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.row}>
                <Typography className={classes.subtitle}>
                    Share your link today and connect with your clients to schedule their next appointment.
                </Typography>
            </Box>
            <Box className={clsx(classes.row, classes.rowInput)}>
                <TextFieldWithButton
                    value={onlineBookingPath}
                    label={buttonMessage}
                    startIcon={buttonIcon}
                    blackButton={true}
                    onClick={copyToClipboard}
                    classNameCustom="onlineBookingInput"
                />
            </Box>
        </Box>
    );
};

export default Profile;
