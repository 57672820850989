import React, { FunctionComponent, FocusEvent, useState } from 'react';
import { Box, Typography, TextField as MUITextField } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Duration } from 'model/Service';
import clsx from 'clsx';
import { commonStyles } from 'Theme';

interface Props {
    duration: Duration;
    small?: boolean;
    error?: boolean;
    onChange: (durarion: Duration) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            gap: 8,
            width: '100%',
            display: 'flex',
            alignItems: 'center'
        },
        label: {
            fontSize: 16,
            fontWeight: 500,
            [theme.breakpoints.up('xl')]: {
                fontSize: 16
            }
        },
        smallLabel: {
            fontSize: 14,
            fontWeight: 400,

            [theme.breakpoints.up('md')]: {
                fontSize: 16,
                fontWeight: 500
            }
        },
        value: {
            'width': 92,

            [theme.breakpoints.up('md')]: {
                width: 104
            },

            '& .MuiOutlinedInput-root': {
                height: 45
            },
            '& .MuiOutlinedInput-input': {
                textAlign: 'center',

                [theme.breakpoints.up('md')]: {
                    fontSize: '20px'
                }
            }
        },
        smallValue: {
            '& .MuiOutlinedInput-root': {
                width: 64,
                height: 45,

                [theme.breakpoints.up('lg')]: {
                    width: 72
                },
                [theme.breakpoints.up('xl')]: {
                    width: 100
                }
            },
            '& .MuiOutlinedInput-input': {
                padding: 0,
                fontSize: 15,
                textAlign: 'center',

                [theme.breakpoints.up('md')]: {
                    fontSize: '20px'
                }
            }
        }
    })
);

export const ServiceDurationValues: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const commonClasses = commonStyles();

    const [hoursVal, setHoursVal] = useState(
        props.duration.hours ? props.duration.hours : 0
    );

    const [minutesVal, setMinutesVal] = useState(
        props.duration.minutes ? props.duration.minutes : 0
    );

    const hoursChangeHandler = (values: NumberFormatValues): void => {
        setHoursVal(values.floatValue || 0);
        props.onChange({
            hours: values.floatValue || 0,
            minutes: minutesVal
        });
    };

    const minutesChangeHandler = (values: NumberFormatValues): void => {
        setMinutesVal(values.floatValue || 0);
        props.onChange({
            hours: hoursVal,
            minutes: values.floatValue || 0
        });
    };

    const valueStyle = clsx(commonClasses.field, {
        [classes.value]: !props.small,
        [classes.smallValue]: props.small
    });
    const labelStyle = clsx({
        [classes.label]: !props.small,
        [classes.smallLabel]: props.small
    });

    return (
        <Box className={classes.container}>
            <NumberFormat
                id="services_add_service_input_service_duration_hours"
                value={hoursVal}
                allowNegative={false}
                decimalScale={0}
                customInput={MUITextField}
                variant="outlined"
                error={props.error}
                onValueChange={hoursChangeHandler}
                className={valueStyle}
                isAllowed={values => {
                    return (values.floatValue || 0) < 11;
                }}
                onFocus={(event: any) => event.target.select()}
            />
            <Typography className={labelStyle}>Hours</Typography>
            <NumberFormat
                id="services_add_service_input_service_duration_minutes"
                value={minutesVal}
                allowNegative={false}
                decimalScale={0}
                error={props.error}
                customInput={MUITextField}
                variant="outlined"
                onValueChange={minutesChangeHandler}
                className={valueStyle}
                allowLeadingZeros={false}
                isAllowed={values => {
                    return (values.floatValue || 0) < 60;
                }}
                onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
            />
            <Typography className={labelStyle}>Minutes</Typography>
        </Box>
    );
};

export default ServiceDurationValues;
