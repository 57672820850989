import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { RootState } from 'store';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { PetsState, PetsStatus, fetchPetsThunk } from '@spike/pets-action';

// Reducer
import { useDispatch, useSelector } from 'react-redux';
import { useApiClientWrapper } from 'hooks';
import Pet from '@spike/pet-model';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from 'constants/index';
import PetsComponent from './PetsComponent';
import { Spinner } from 'components/UI';
import { fetchStaffThunk } from 'actions/staff/StaffActions';
import { AlertNewBooking } from '../UI/AlertNewBooking';
import clsx from 'clsx';
import { Moment } from 'moment-timezone';
import { PetService } from '@spike/new-booking-model';

interface AppointmentDataVaraible {
    service: PetServiceWrapper;
    staffId: number;
    date: Moment;
}

interface AppointmentMultiple {
    clientId: number;
    petId: number;
    createdByStaffId: number;
    notes?: string;
    variableData: Array<AppointmentDataVaraible>;
}

interface PetServiceWrapper extends PetService {
    duration?: Duration;
}

interface Props {
    parentID: number | undefined;
    petId: number | undefined;
    readOnly?: boolean;
    petsLoaded?: boolean;
    multiplePets?: boolean;
    hideSelect?: boolean;
    multipleAppointments?: Array<AppointmentMultiple>;
    handlerPet: (value: Pet) => void;
    onAddPet?: (clientId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '16px',
            marginBottom: 18,
            [theme.breakpoints.up('md')]: {
                marginBottom: 20
            }
        },
        alert: {
            marginBottom: '0px !important'
        },
        alertText: {
            fontSize: 14,
            lineHeight: '20px'
        },
        disabled: {
            pointerEvents: 'none',
            cursor: 'default'
        },
        clientLinkContainer: {
            paddingTop: 14,
            [theme.breakpoints.up('md')]: {
                paddingTop: 16
            }
        },
        clientLink: {
            fontWeight: 500,
            cursor: 'pointer',
            textDecoration: 'none',
            color: '#000000',
            fontSize: 16,
            lineHeight: '24px',
            [theme.breakpoints.up('md')]: {
                lineHeight: '27px'
            }
        }
    })
);

export const PetSearch: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const petsStateFilter = useSelector<RootState, PetsState>(
        state => state.pets
    );
    const loading = useSelector<RootState, boolean>(
        state => state.pets.loading
    );

    const [emptyPetsFilter, setEmptyPetsFilter] = useState(false);
    const [petId, setPetId] = useState<number | undefined>(props.petId);
    const [petsLoad, setPetLoads] = useState<Array<Pet>>();
    const [showPets, setShowPets] = useState(false);

    const pets = useMemo(() => {
        return petsStateFilter.list.filter(
            pet => !pet.deleted && !pet.deceased
        );
    }, [petsStateFilter.list]);

    useEffect(() => {
        if (props.parentID !== undefined) {
            if (!props.petsLoaded) {
                dispatch(fetchPetsThunk(apiClientWrapper, props.parentID));
            }
            dispatch(fetchStaffThunk());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.parentID]);

    useEffect(() => {
        if (petsStateFilter.status === PetsStatus.FetchSuccess) {
            switch (pets.length) {
                case 0:
                    setEmptyPetsFilter(true);
                    break;
                case 1:
                    setEmptyPetsFilter(false);
                    break;
                default:
                    setEmptyPetsFilter(false);
                    break;
            }

            if (pets.length === 1) {
                handlerPet(pets[0]);
                setPetId(pets[0].id);
            } else {
                if (props.petId === undefined) {
                    handlerPet(undefined);
                } else if (pets.some(pet => pet.id === props.petId)) {
                    handlerPet(pets.find(pet => pet.id === props.petId));
                }
            }

            if (pets[0] && pets[0].clientId === props.parentID) {
                if (
                    props.multipleAppointments &&
                    props.multipleAppointments.length > 0 &&
                    !props.readOnly
                ) {
                    const petsFiltered: Array<Pet> = [];
                    pets.forEach(pet => {
                        const petFounded = props.multipleAppointments?.find(
                            (app: AppointmentMultiple) => app.petId === pet.id
                        );
                        if (!petFounded) {
                            petsFiltered.push(pet);
                        }
                    });
                    setPetLoads(
                        petsFiltered.filter(
                            (pet: Pet) => !pet.deleted && !pet.deceased
                        )
                    );
                } else {
                    setPetLoads(
                        pets.filter((pet: Pet) => !pet.deleted && !pet.deceased)
                    );
                }
                setShowPets(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [petsStateFilter.status]);

    const handlerPet = (value: Pet | undefined) => {
        setPetId(value?.id);

        if (value !== undefined) {
            props.handlerPet(value);
            setPetId(value.id);
        }
    };

    const emptyPetComponent = (
        <Box id="booking_div_no_pets_alert">
            <AlertNewBooking
                className={classes.alert}
                bWidth="2px"
                icon={faCircleInfo}
                iconSize="lg"
                iconColor="#BAA997"
                bgColor="#F8F5F1"
                bdColor="#BCB8AE"
            >
                <Typography className={classes.alertText}>
                    This client has no pets.
                </Typography>
            </AlertNewBooking>
        </Box>
    );

    useEffect(() => {
        if (emptyPetsFilter) amplitude.track(AMPLITUDE.LOAD_CLIENT_HAS_NO_PETS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emptyPetsFilter]);

    return (
        <Box>
            {loading ? (
                <Spinner />
            ) : (
                <Box className={clsx({ [classes.disabled]: props.readOnly })}>
                    {!props.hideSelect && (
                        <Typography className={classes.title}>Pets</Typography>
                    )}
                    {emptyPetsFilter && emptyPetComponent}
                    {emptyPetsFilter && (
                        <Box className={classes.clientLinkContainer}>
                            <Box
                                id="booking_div_add_client"
                                onClick={() =>
                                    props.onAddPet &&
                                    props.onAddPet(props.parentID!)
                                }
                                className={classes.clientLink}
                            >
                                + Add Pet
                            </Box>
                        </Box>
                    )}
                    {showPets && (
                        <PetsComponent
                            parentID={props.parentID}
                            pets={petsLoad!}
                            petId={petId}
                            multiplePets={props.multiplePets}
                            hideSelect={props.hideSelect}
                            onPetSelect={handlerPet}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default PetSearch;
