import {OLBStatusState} from "./OLBStatusState";
import {Reducer} from "redux";
import {BusinessSettingsAction, OLB_ACTIVATE_DEACTIVATE_SUCCESS_ON_TYPE} from "../../actions/businessSettingsSidebar/BusinessSettingsActionsTypes";

const ACTIVE = "Active"
const INACTIVE = "Inactive"
const initialState = {
    status: ACTIVE
}

export const OLBStatusReducer: Reducer<OLBStatusState, BusinessSettingsAction> = (state = initialState, action) => {
    switch (action.type) {
        case OLB_ACTIVATE_DEACTIVATE_SUCCESS_ON_TYPE:
            return {
                ...state,
                status: action.payload.status == 'active' ? ACTIVE : INACTIVE
            }
        default:
            return state
    }
}
