import React, { FunctionComponent, useState } from 'react';
import {
    Box,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { faCircleInfo, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHasPremiumAccess } from 'hooks';

interface Props {
    report: boolean;
    onChange: (report: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('lg')]: {
                paddingLeft: '50px'
            }
        },
        title: {
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,
            marginBottom: 16
        },
        subtitle: {
            fontSize: 14,
            color: '#000',
            marginBottom: 14,
            [theme.breakpoints.up('md')]: {
                fontSize: 16
            }
        },
        content: {
            'gap': 32,
            'display': 'flex',
            'flexDirection': 'row',
            'alignItems': 'start',

            [theme.breakpoints.only('lg')]: {
                fontSize: '15px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '20px'
            },

            '& .MuiFormControlLabel-root': {
                margin: 0
            },
            '& .MuiButtonBase-root': {
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 8,
                paddingBottom: 0
            },
            '& .MuiSvgIcon-root': {
                color: '#000',
                [theme.breakpoints.only('lg')]: {
                    fontSize: '20px'
                },
                [theme.breakpoints.up('xl')]: {
                    fontSize: '24px'
                }
            },
            '& .MuiFormControlLabel-label': {
                color: '#000',
                [theme.breakpoints.only('lg')]: {
                    fontSize: '16px'
                },
                [theme.breakpoints.up('xl')]: {
                    fontSize: '18px'
                }
            }
        },
        radio: {
            [theme.breakpoints.only('lg')]: {
                marginLeft: '43px'
            },
            [theme.breakpoints.up('xl')]: {
                marginLeft: '57px'
            }
        },
        alert: {
            width: '100%',
            border: '2px solid #BCB8AE',
            minHeight: '51px',
            background: '#F8F5F1',
            borderRadius: 16,
            display: 'flex',
            alignItems: 'center',
            padding: '13px 16px',
            marginBottom: 22,
            fontSize: 14,
            gap: 10
        },
        alertIcon: {
            color: '#BCB8AE',
            width: 24,
            height: 24
        }
    })
);

export const ServiceReport: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const hasReportsPremiumAccess = useHasPremiumAccess('groomming_reports');
    const [report, setReport] = useState(props.report ? 'yes' : 'no');

    const handleChange = (e: any): void => {
        const value = e.target.value === 'yes' ? true : false;
        setReport(e.target.value);
        props.onChange(value);
    };

    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>Report</Typography>
            <Typography className={classes.subtitle}>
                Include a report for this service?
            </Typography>
            <Box className={classes.alert}>
                <FontAwesomeIcon
                    icon={faInfoCircle}
                    className={classes.alertIcon}
                />
                <Typography>
                    Reports only applies for{' '}
                    <Link to={{ pathname: '/business_settings/info/plans' }}>
                        <b>Premium</b>
                    </Link>{' '}
                    members.
                </Typography>
            </Box>
            <RadioGroup
                value={report}
                onChange={handleChange}
                className={classes.content}
            >
                <FormControlLabel
                    id="services_add_service_radio_report_yes"
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                />
                <FormControlLabel
                    id="services_add_service_radio_report_no"
                    value="no"
                    control={<Radio className={classes.radio} />}
                    label="No"
                />
            </RadioGroup>
        </Box>
    );
};

export default ServiceReport;
