import { MarketplacePetSize } from '@spike/marketplace-model';
import { Ordered } from '@spike/model';

export const getSizeByWeight = (
    weight: number,
    sizes: Array<Ordered<MarketplacePetSize>>
) => {
    const orderedSizes = sortSizes(sizes).map(
        orderedSize => orderedSize.element
    );

    let size = orderedSizes.find(
        size =>
            (size.minWeight === null || weight >= size.minWeight) &&
            (size.maxWeight === null || weight <= size.maxWeight)
    );

    if (size === undefined && orderedSizes.length > 0) {
        const [firstSize] = orderedSizes;
        const [lastSize] = orderedSizes.reverse();

        size =
            firstSize.minWeight === null || weight < firstSize.minWeight
                ? firstSize
                : undefined;

        if (size === undefined) {
            size =
                lastSize.minWeight === null || weight > lastSize.minWeight
                    ? lastSize
                    : undefined;
        }
    }

    return size;
};

export const sortSizes = (sizes: Array<Ordered<MarketplacePetSize>>) =>
    sizes.sort(
        (orderedSize, otherOrderedSize) =>
            orderedSize.order - otherOrderedSize.order
    );
