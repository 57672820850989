import {BulkUploadState} from "./BulkUploadState";
import {BULKUPLOAD_PROGRESS_TYPE} from "../../actions/bulkUpload/bulkUploadActionType";


const initialState: BulkUploadState = {
    event: '',
    errors: 0,
    percentage: 0,
    record_number: 0,
    successfully_imported: 0,
    total_records: 0
}

export const bulkUploadReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case BULKUPLOAD_PROGRESS_TYPE:
            return{
                ...state,
                event: action.payload.event,
                errors: action.payload.errors,
                percentage: action.payload.percentage,
                record_number: action.payload.record_number,
                successfully_imported: action.payload.successfully_imported,
                total_records: action.payload.total_records
            }
        default:
            return state;
    }
}

export default bulkUploadReducer;
