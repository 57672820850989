import { createStyles, makeStyles } from '@material-ui/core/styles';
import TabsHeader from 'components/UI/TabsHeader/TabsHeader';
import { FunctionComponent } from 'react';

interface TabsProps {
    tabId: string;
    selectTabHandler: (tabId: string) => void;
}

// Define estilos personalizados
const useStyles = makeStyles(() =>
    createStyles({
        tabsHeaderContainer: {
            marginTop: '24px',
            borderBottom: '2px solid #E5E5E5'
        },
        tabContainer: {
            paddingBottom: '16px'
        },
        tabLabel: {
            fontSize: '14px',
            fontWeight: 500
        }
    })
);

export const profileTabId = 'profile';
export const settingsTabId = 'settings';

export const Tabs: FunctionComponent<TabsProps> = props => {
    const classes = useStyles();

    const tabs = [
        { id: profileTabId, name: 'Profile' },
        { id: settingsTabId, name: 'Settings' }
    ];

    return (
        <TabsHeader
            tabs={tabs}
            selectedTabId={props.tabId}
            onClick={props.selectTabHandler}
            className={classes.tabsHeaderContainer}
            tabHeaderClasses={{
                container: classes.tabContainer,
                label: classes.tabLabel
            }}
        />
    );
};

export default Tabs;
