import {FunctionComponent, useEffect} from "react";
import {useAuth} from "../hooks";
import {useDispatch} from "react-redux";
import ActionCable, {Cable, Channel} from "actioncable";
import {BulkUploadWebSocketMessage} from "../model/WebSockets";
import useNonInitialEffect from "@versiondos/hooks";
import {setBulkUploadThunk} from "../actions/bulkUpload/bulkUploadActions";


const BulkUploadWebSockets: FunctionComponent = () => {
    const auth = useAuth();
    const dispatch = useDispatch();

    const prepareWebSocket = () => {

        let cable: Cable;
        let channel: Channel;

        console.info({"authData": auth, "signed": auth.signedIn});

        if (auth.signedIn) {
            [cable, channel] = subscribe(auth.marketplaceId!, auth.user!.staffId);
        }

        return () => {
            unsubscribe(cable, channel);
        };

    }

    useEffect(() => {
        prepareWebSocket();
    }, []);

    useNonInitialEffect(() => {
        prepareWebSocket();
    }, [auth]);

    const subscribe = (marketplaceId: number, staffId: number): [Cable, Channel] => {
        const cable = ActionCable.createConsumer(
            `${process.env.REACT_APP_WEBSOCKETS_HOST_URL}${process.env.REACT_APP_WEBSOCKETS_BASE_URL}?access_token=${auth.token}`
        );

        const channel = cable!.subscriptions.create(
            {
                channel: "CustomersImportChannel",
                marketplace_id: marketplaceId,
                staff_id: staffId,
            },
            {received: (message: any) => messageHandler(message)}
        );

        console.info({
            action: "BulkUploadWebSockets subscribed",
            channel,
        });

        return [cable, channel];
    };

    const unsubscribe = (cable: Cable, channel: Channel) => {
        cable && cable.disconnect();
        channel && channel.unsubscribe();
    };

    const messageHandler = (message: BulkUploadWebSocketMessage) => {

        console.info({
            action: "Notifications message received",
            message,
        });

        if (message.event !== "") {
            dispatch(setBulkUploadThunk(message));
        }
    };

    return null;
}

export default BulkUploadWebSockets;
