import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';

import {
	Tip as InvoiceTip,
	InvoiceTipDistribution
} from '@spike/invoice-model';
import { Option } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import clsx from 'clsx';
import { OptionsField } from 'components/UI';
import round from 'lodash/round';
import { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { reduceResolution, wbp } from 'Theme';
import Section from '../Section';
import Tip from './Tip';
import TipDistribution from './TipDistribution';
import { CheckoutTip, createNotSelectedTip, TipOption } from './TipModel';
import {
	convertToCheckoutTip,
	convertToInvoiceTip,
	isCustomCheckoutTip,
	isDefaultCheckoutTip,
	isNoCheckoutTip,
	isSameTip
} from './TipUtils';
import CustomTip from './CustomTip';

interface TipsProps {
	invoiceId: number;
	invoiceTip: InvoiceTip | null;
	tipDistribution: Array<InvoiceTipDistribution>;
	subtotalWithoutTipTaxes: string;
	saving: boolean;
	className?: string;
	totalBalance?: (value: string) => void;
	onChange: (tip: InvoiceTip | null) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cell: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%'
		},
		optionsContainer: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: `${reduceResolution(20)}px`,
				height: `${reduceResolution(32)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '20px',
				height: '32px'
			}
		},
		onlyOptions: {
			marginTop: '0px'
		},
		customContainer: {
			[theme.breakpoints.down(wbp)]: {
				marginTop: `${reduceResolution(29)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '29px'
			}
		},
		loading: {
			opacity: 0.2
		}
	})
);

const customOption: Option<string> = { id: 'custom', name: 'Custom Amount' };
const noTipOption: Option<string> = { id: 'no_tip', name: 'No Tip' };

const options: Array<Option<string>> = [customOption, noTipOption];

const zeroAmountDefaultTips: Array<TipOption> = [
	{
		uuid: '5',
		amount: '5',
		percentage: null
	},
	{
		uuid: '10',
		amount: '10',
		percentage: null
	},
	{
		uuid: '15',
		amount: '15',
		percentage: null
	}
];

export const Tips: FunctionComponent<TipsProps> = props => {
	const classes = useStyles();

	const isZeroAmount = Number(props.subtotalWithoutTipTaxes) === 0;

	const amountDefaultTips = useSelector<RootState, Array<TipOption>>(state =>
		state.marketplace.marketplace.tips
			.filter(tip => tip.percentage > 0)
			.map(tip => ({
				uuid: tip.uuid,
				amount: round(
					(Number(props.subtotalWithoutTipTaxes) * tip.percentage) /
						100,
					2
				)
					.toFixed(2)
					.toString(),
				percentage: String(tip.percentage)
			}))
	);

	const defaultTips = isZeroAmount
		? zeroAmountDefaultTips
		: amountDefaultTips;
	const [saving, setSaving] = useState(props.saving);

	const [selectedOption, setSelectedOption] = useState<
		Option<string> | undefined
	>(undefined);
	const [checkoutTip, setCheckoutTip] = useState<CheckoutTip>(
		createNotSelectedTip()
	);

	useEffect(() => {
		const checkoutTip = convertToCheckoutTip(props.invoiceTip, defaultTips);

		if (isDefaultCheckoutTip(checkoutTip)) {
			setSelectedOption(undefined);
		} else if (isNoCheckoutTip(checkoutTip)) {
			setSelectedOption(noTipOption);
		} else if (isCustomCheckoutTip(checkoutTip)) {
			setSelectedOption(customOption);
		} else {
			setSelectedOption(undefined);
		}

		setCheckoutTip(checkoutTip);
	}, []);

	useNonInitialEffect(() => {
		setSaving(props.saving);
	}, [props.saving]);

	const changeDefaultTipHandler = (defaultTip: TipOption) => {
		const newCheckoutTip = {
			default: { ...defaultTip },
			customTip: null,
		};

		const actualInvoiceTip = convertToInvoiceTip(checkoutTip);
		const newInvoiceTip = convertToInvoiceTip(newCheckoutTip);

		setSelectedOption(undefined);
		setCheckoutTip(newCheckoutTip);

		if(!isSameTip(actualInvoiceTip, newInvoiceTip)){
			setSaving(true);
			props.onChange(newInvoiceTip);
		}
	};

	const changeOptionHandler = (option: Option<string>) => {

		setSelectedOption(option);
		const newCheckoutTip = {
			default: null,
			customTip: {amount: '0', percentage: null}
		};

		if (
			!isSameTip(
				convertToInvoiceTip(newCheckoutTip),
				convertToInvoiceTip(checkoutTip)
			)
		) {
			setSaving(true);
			setCheckoutTip(newCheckoutTip);
			props.onChange(convertToInvoiceTip(newCheckoutTip));
		}
	};

	const changeCustomTipHandler = (customTip: InvoiceTip | null) => {
		setSaving(true);
		const checkoutTip = {
			default: null,
			customTip
		};
		setCheckoutTip(checkoutTip);
		props.onChange(convertToInvoiceTip(checkoutTip));
	};

	return (
		<Section title="Add Tip" className={props.className}>
			<>
				{defaultTips.length > 0 && (
					<Grid container spacing={2}>
						{defaultTips.map((defaultTip, index) => (
							<Grid
								item
								xs={4}
								className={classes.cell}
								key={defaultTip.uuid}
							>
								<Tip
									id={`booking_payment_tip_${index}`}
									percentage={defaultTip.percentage!}
									amount={defaultTip.amount!}
									onClick={() =>
										changeDefaultTipHandler(defaultTip)
									}
									selected={
										checkoutTip?.default?.uuid ===
										defaultTip.uuid
									}
									loading={props.saving}
								/>
							</Grid>
						))}
					</Grid>
				)}
				<Grid
					container
					className={clsx(classes.optionsContainer, {
						[classes.onlyOptions]: defaultTips.length === 0,
						[classes.loading]: saving
					})}
				>
					<OptionsField
						options={options}
						selected={selectedOption || undefined}
						onChange={changeOptionHandler}
					/>
				</Grid>
				{selectedOption?.id === customOption.id && (
					<Grid
						container
						className={clsx(classes.customContainer, {
							[classes.loading]: saving
						})}
					>
						<CustomTip
							tip={checkoutTip.customTip}
							onlyAmount={isZeroAmount}
							saving={saving}
							onChange={changeCustomTipHandler}
						/>
					</Grid>
				)}
				{selectedOption?.id !== noTipOption.id &&
					props.tipDistribution.length > 0 && (
						<TipDistribution
							className={props.className}
							tipDistribution={props.tipDistribution}
							loading={saving}
						/>
					)}
			</>
		</Section>
	);
};

export default Tips;
