import React, { FunctionComponent, Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Alert as AlertModel, Notification as NotificationModel, NotificationType } from "@spike/notifications-action";
import { Moment } from "moment-timezone";
import Alert from "./Alert";
import Booked from "./Booked";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      //position: "absolute",
      position: "fixed",
      zIndex: 10000,
      right: "0px",
      top: "10px",
      [theme.breakpoints.down("sm")]: {
        left: 0,
        padding: '0px 20px'
      },
    },
    alert: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      cursor: "pointer",
      color: "white",
      padding: "10px",
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "8px",
        minWidth: "150px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "8px",
        minWidth: "200px",
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: "10px",
        minWidth: "300px",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "10px",
        minWidth: "300px",
      },
    },
    alertSuccess: {
      backgroundColor: "green",
    },
    alertWarning: {
      backgroundColor: "yellow",
      color: "black",
    },
    alertError: {
      backgroundColor: "red",
    },
    alertIcon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      [theme.breakpoints.down("sm")]: {
        width: "16px",
        height: "16px",
      },
      [theme.breakpoints.only("md")]: {
        width: "16px",
        height: "16px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "18px",
        height: "18px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "24px",
        height: "24px",
      },
    },
    alertText: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingLeft: "10px",
    },
    text: {
      fontFamily: "Poppins",
      fontWeight: 400,
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
      },
    },
  })
);

interface Wrapper {
  uuid: string;
  timestamp: Moment;
  alert?: AlertModel;
  notification?: NotificationModel;
}

export const Notifications: FunctionComponent = () => {
  const classes = useStyles();

  const alertWrappers: Array<Wrapper> = useSelector<RootState, Array<AlertModel>>((state) => state.notifications.alerts).map((alert) => ({
    uuid: alert.key,
    timestamp: alert.timestamp,
    alert,
  }));

  const notificationWrappers: Array<Wrapper> = useSelector<RootState, Array<NotificationModel>>(
    (state) => state.notifications.notifications
  ).map((notification) => ({
    uuid: notification.key,
    timestamp: notification.timestamp,
    notification
  }));

  const wrappers: Array<Wrapper> = [...alertWrappers, ...notificationWrappers].sort((wrapper, otherWrapper) => {
    return wrapper.timestamp.unix() - otherWrapper.timestamp.unix()
  });

  return (
    <Fragment>
      {wrappers.length > 0 && (
        <Box className={classes.container}>
          {wrappers.map((wrapper) => {
            if (wrapper.alert) {
              return (<Alert key={wrapper.uuid} alert={wrapper.alert!} />);
            } else if (wrapper.notification && wrapper.notification.type === NotificationType.BOOKED) {
              return (<Booked key={wrapper.uuid} notification={wrapper.notification!} />);
            } else if (wrapper.notification && wrapper.notification.type === NotificationType.RESCHEDULED) {
              return (<Booked key={wrapper.uuid} notification={wrapper.notification!} rescheduled={true} />);
            } else {
              return null;
            }
          })}
        </Box>
      )}
    </Fragment>
  );
};

export default Notifications;
